import React from "react";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";

import { FooterOverlay, Newsletter } from "../../components";
import { images } from "../../constants";
import "./Footer.css";

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <FooterOverlay />
    {/*<Newsletter />*/}

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">Blajat Rd. Inside Gold's gym, Ismailia, Egypt</p>
        <p className="p__opensans">+201281208348</p>
        {/*<p className="p__opensans">+20 XXXXX-XXXX</p>*/}
      </div>

      <div className="app__footer-links_logo">
        <div className="p__opensans">Bonne Sante</div>

        <img
          src={images.spoon}
          className="spoon__img"
          style={{ marginTop: 15 }}
        />
        <div className="app__footer-links_icons">
          <a href="http://facebook.com/profile.php?id=61556699184740"><FiFacebook /></a>
          <a href="https://www.instagram.com/bonne_sante.eg/"><FiInstagram /></a>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Monday-Friday:</p>
        <p className="p__opensans">09:00 AM - 11:00 PM</p>
        <p className="p__opensans">Saturday-Sunday:</p>
        <p className="p__opensans">09:00 AM - 11:00 PM</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2024 Bonne Sante. All Rights reserved.</p>
    </div>
    <p className="p__opensans" style={{ fontSize: '11px' }}>Developed by Cairo Code +201551870820.</p>
  </div>
);

export default Footer;
