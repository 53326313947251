// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          menu: {
            breakfast: 'Breakfast',
            chickenMeals: 'Chicken Meals',
            beefMeals: 'Beef Meals',
            salad: 'Salad',
            pasta: 'Pasta',
            pizza: 'Pizza',
            wraps: 'Wraps',
            extras: 'Extras',
            viewMore: 'View More',
          },
        },
      },
      ar: {
        translation: {
          menu: {
            breakfast: 'إفطار',
            chickenMeals: 'وجبات الدجاج',
            beefMeals: 'وجبات لحم البقر',
            salad: 'سلطة',
            pasta: 'باستا',
            pizza: 'بيتزا',
            wraps: 'ورقائق',
            extras: 'إضافات',
            viewMore: 'عرض المزيد',
          },
        },
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
