import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { SubHeading, MenuItem } from "../../components";
import { data, Adata } from "../../constants";
import i18n from '../../i18n';
import "./SpecialMenu.css";

const SpecialMenu = () => {
  const { t } = useTranslation();
  const [currentData, setCurrentData] = useState(data);

  const handleLanguageChange = () => {
    i18n.changeLanguage('ar');
    setCurrentData(currentData === data ? Adata : data);
  };

  return (
    <div className="app__specialMenu flex__center section__padding" id="menu">
      <div className="app__specialMenu-title">
        <SubHeading title="Menu that fits your palatte" />
        <h1 className="headtext__cormorant">Today&apos;s Special</h1>
        <div style={{ marginTop: 15 }}>
          <button
            type="button"
            className="custom__button"
            onClick={handleLanguageChange}
          >
            {t('تغير اللغه')} (English)/(العربية)
          </button>
        </div>
      </div>

      <div className="app__specialMenu-menu">
        {/* Breakfast Section */}
        <div className="app__specialMenu-cat1  flex__center">
          <p className="app__specialMenu-menu_heading">
Breakfast | الفطور          </p>

          <div className="app__specialMenu_menu_items">
            {currentData.cat1.map((cat1, index) => (
              <MenuItem
                key={cat1.title + index}
                title={cat1.title}
                price={cat1.price}
                tag0={cat1.tag0}
                tag1={cat1.tag1}
              />
            ))}
          </div>
        </div>

        {/* Separator */}
        <div className="app_specialMenu-menu_separator"></div>
      </div>

      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-cat1  flex__center">
          <p className="app__specialMenu-menu_heading">Chicken Meals | وجبات الدجاج</p>
          <div className="app__specialMenu_menu_items">
            {currentData.cat3.map((cat3, index) => (
              <MenuItem
                key={cat3.title + index}
                title={cat3.title}
                price={cat3.price}
                tag0={cat3.tag0}
                tag1={cat3.tag1}
              />
            ))}
          </div>
        </div>

        {/*<div className="app__specialMenu-menu_img">
        <img src={images.menu} alt="menu__img" />
          </div>*/}
        <div className="app_specialMenu-menu_separator"></div>

        <div className="app__specialMenu-menu_cat2  flex__center">
          <p className="app__specialMenu-menu_heading">Beef Meals | وجبتا اللحم</p>
          <div className="app__specialMenu_menu_items">
            {currentData.cat2.map((cat2, index) => (
              <MenuItem
                key={cat2.title + index}
                title={cat2.title}
                price={cat2.price}
                tags={cat2.tag0}
                tag1={cat2.tag1}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-cat1  flex__center">
          <p className="app__specialMenu-menu_heading">Salad | السلطه</p>
          <div className="app__specialMenu_menu_items">
            {currentData.cat5.map((cat5, index) => (
              <MenuItem
                key={cat5.title + index}
                title={cat5.title}
                price={cat5.price}
                tag0={cat5.tag0}
                tag1={cat5.tag1}
              />
            ))}
          </div>
        </div>

        {/*<div className="app__specialMenu-menu_img">
        <img src={images.menu} alt="menu__img" />
          </div>*/}
        <div className="app_specialMenu-menu_separator"></div>

        <div className="app__specialMenu-menu_cat2  flex__center">
          <p className="app__specialMenu-menu_heading">Pasta | المكرونه</p>
          <div className="app__specialMenu_menu_items">
            {currentData.cat6.map((cat6, index) => (
              <MenuItem
                key={cat6.title + index}
                title={cat6.title}
                price={cat6.price}
                tags={cat6.tag0}
                tag1={cat6.tag1}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-cat1  flex__center">
          <p className="app__specialMenu-menu_heading">Pizza | البيتزا</p>
          <div className="app__specialMenu_menu_items">
            {currentData.cat7.map((cat7, index) => (
              <MenuItem
                key={cat7.title + index}
                title={cat7.title}
                price={cat7.price}
                tag0={cat7.tag0}
                tag1={cat7.tag1}
              />
            ))}
          </div>
        </div>

        {/*<div className="app__specialMenu-menu_img">
        <img src={images.menu} alt="menu__img" />
          </div>*/}
        <div className="app_specialMenu-menu_separator"></div>

        <div className="app__specialMenu-menu_cat2  flex__center">
          <p className="app__specialMenu-menu_heading">Wraps | الكريب</p>
          <div className="app__specialMenu_menu_items">
            {currentData.cat8.map((cat8, index) => (
              <MenuItem
                key={cat8.title + index}
                title={cat8.title}
                price={cat8.price}
                tags={cat8.tag0}
                tag1={cat8.tag1}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-cat1  flex__center">
          <p className="app__specialMenu-menu_heading">Extras | اضافات</p>
          <div className="app__specialMenu_menu_items">
            {currentData.cat9.map((cat9, index) => (
              <MenuItem
                key={cat9.title + index}
                title={cat9.title}
                price={cat9.price}
                tag0={cat9.tag0}
                tag1={cat9.tag1}
              />
            ))}
          </div>
        </div>
      </div>


    </div>
  )
};

export default SpecialMenu;
