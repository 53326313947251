import images from './images';

const cat1 = [
  {
    title: 'Egyptian Breakfast',
    price: '85,00 EGP',
    tag0: 'Eggs + FavaBeans + GreenBurger + cottage cheese + Fries + GreenSalad + Toast ',
    tag1: '49 grams of protien + 61 grams of carbs + 25 grams of Fats',
  },
  {
    title: 'Mix Cheese toast',
    price: '55,00 EGP',
    tag0: 'Mix cheese + Cappage + Fries (AirFried) + GreenSalad',
    tag1: '24 grams of protien + 50 grams of carbs + 20 grams of Fats',
  },
  {
    title: 'Grilled checkin toast',
    price: '75,00 EGP',
    tag0: 'Chicken + Cappage + Fries (AirFried) + Motzarela + GreenSalad',
    tag1: '45 grams fo protien + 74 grams of Carbs + 23 grams of Fats',
  },
  {
    title: 'Turkey Toast',
    price: '65,00 EGP',
    tag0: 'Turkey + Cappage + motzarela = Fried (AirFried) + GreenSalad',
    tag1: '36 grams of protien + 77 grams of Carbs + 17 grams of Fats',
  },
  {
    title: 'omelette',
    price: '65,00 EGP',
    tag0: '2 Eggs + 2 Toast + Potato Wedges + GreenSalad',
    tag1: '25 grams of protien + 50.8 grams of Carbs + 22 grams of Fats',
  },
  {
    title: 'Spanish omelette',
    price: '65,00 EGP',
    tag0: '2 Eggs + beell peper + 2 Toast + motzarella + Potato Wedges + GreenSalad',
    tag1: '32 grams of protien + 51.2 grams of Carbs + 30 grams of Fats',
  },
  {
    title: 'Mushroom omelette',
    price: '65,00 EGP',
    tag0: '2 Eggs + Mushroom + 2 Toast + Potato Wedges + GreenSalad',
    tag1: '25 grams of protien + 51.2 grams of Carbs + 22 grams of Fats',
  },
];

const cat2 = [
  {
    title: 'Bonne Sante',
    price: '235,00 EGP',
    tag0: 'Meat + Rice + mashed Potato + Sauteed',
    tag1: '53 grams of protien + 83 grams of carbs + 12 grams of Fats ',
  },
  {
    title: "Meat balls",
    price: '180,00 EGP',
    tag0: 'Meat balls + Red Sauce + Rice + mashed Potato + Sauteed',
    tag1: '52 grams fo protien + 83 grams of carbs + 19 grams of Fats',
  },
  {
    title: 'Kofta',
    price: '180,00 EGP',
    tag0: 'Kofta + Rice + Sauteed + mashed Potato',
    tag1: '50 grams of protien + 77 grams of Carbs + 14 grams of Fats',
  },
];

const cat3 = [
  {
    title: 'Grilled Chicken',
    price: '145,00 EGP',
    tag0: 'Grilles chicken + Rice + mashed Potato + Sauteed',
    tag1: '45.5 grams fo protien + 85 grams of Carbs + 9 grams of Fats',
  },  {
    title: 'chicken lemon',
    price: '160,00 EGP',
    tag0: 'chicken + lemon sauce + Rice + mashed Potato + Sauteed',
    tag1: '49 grams of protien + 85 grams of Carbs + 14 grams of Fats',
  },  {
    title: 'Crispy chicken',
    price: '155,00 EGP',
    tag0: 'Crispy chicken + white pasta + Sauteed + mashed Potato',
    tag1: '50 grams of protien + 95 grams of Carbs + 13 grams of Fats',
  },  {
    title: 'Fajita chicken',
    price: '150,00 EGP',
    tag0: 'Fajita + Rice + mashed Potato + Sauteed',
    tag1: '45.5 grams of protien + 85 grams of Carbs + 8.5 grams of Fats',
  },  {
    title: 'Crispy potato chicken',
    price: '65,00 EGP',
    tag0: 'Crispy potato + chicken Fajita',
    tag1: '34 grams of protien + 57 grams of Carbs + 11 grams of Fats',
  },  {
    title: 'Rizo',
    price: '65,00 EGP',
    tag0: 'Crispy chicken + Rice + BBQ Sauce',
    tag1: '32 grams of protien + 48 grams of Carbs + 12 grams of Fats',
  },  {
    title: 'Kids meal',
    price: '90,00 EGP',
    tag0: '2 pieces of strips + 3 picecs of nuggets + French Fries (AirFried) + Rice',
    tag1: '',
  },
];



const cat5 = [
  {
    title: 'Cesar sald',
    price: '85,00 EGP',
    tag0: 'Grilled chicken + Cappage + Toast + Cesar Sauce',
    tag1: '37 grams of protien + 25 grams of carbs + 14 grams of Fats',
  },  {
    title: 'Tuna Salad',
    price: '85,00 EGP',
    tag0: 'Tuna + Cappage + Onion + Red Beans + sweet corn + bell pepper + Cherry tomato',
    tag1: '23 grams of protien + 20 grams of carbs + 2.4 grams of Fats',
  },  {
    title: 'Greek Salad',
    price: '75,00 EGP',
    tag0: 'Light feta cheese + Cappage + Cucumber + olives + cherry tomato',
    tag1: '10 grams of protien + 25 grams of carbs + 12 grams of Fats',
  },  {
    title: 'Green Salad',
    price: '30,00 EGP',
    tag0: 'Cappage + Onion + Red Beans + sweet corn + bell pepper + Cherry tomato',
    tag1: '',
  },
];

const cat6 = [
  {
    title: 'Alfredo Pasta',
    price: '95,00 EGP',
    tag0: 'chicken + Pasta + White sauce',
    tag1: '39.5 grams of protien + 45 grams of carbs + 13 grams of Fats',
  },  {
    title: 'Shrimp Pasta',
    price: '135,00 EGP',
    tag0: 'Shrimp + Pasta + White scauce',
    tag1: '30 grams of protien + 46 grams of carbs + 13 grams of Fats',
  },  {
    title: 'Pesto Pasta',
    price: '95,00 EGP',
    tag0: 'Chicken + Pasta + Pesto sauce',
    tag1: '41.5 grams of protien + 45 grams of carbs + 20 grams of Fats',
  },  {
    title: 'Negrsco Pasta',
    price: '110,00 EGP',
    tag0: 'chicken + Pasta + White sacue + Motzarella',
    tag1: '50 grams of protien + 45 grams of carbs + 20 grams of Fats',
  },
];

const cat7 = [
  {
    title: 'chicken pizza',
    price: '85,00 EGP',
    tag0: 'chicken + bell pepper + mushroom + motzarella',
    tag1: '33.5 grams of protien + 20 grams of carbs + 12 grams of Fats',
  },{
    title: 'Crispy chicken pizza',
    price: '85,00 EGP',
    tag0: 'crispy chicken + bell pepper + Motzarella',
    tag1: '36 grams of protien + 25 grams of carbs + 15 grams of Fats',
  },{
    title: 'Tuna pizza',
    price: '100,00 EGP',
    tag0: 'Tuna + bell pepper + motzarella',
    tag1: '25 grams of protien + 12 grams of carbs + 8.7 grams of Fats',
  },
];

const cat8 = [
  {
    title: 'Fajita wrap',
    price: '85,00 EGP',
    tag0: 'chicken + Cappage + bell pepper + Fries (AirFried) + Motzarella',
    tag1: '37 grams of protien + 55 grams of carbs + 11.5 grams of Fats',
  },{
    title: 'Crispy wrap',
    price: '85,00 EGP',
    tag0: 'chicken + Cappage + motzarella + Fries (AirFried)',
    tag1: '40 grams of protien + 58 grams of carbs + 15.5 grams of Fats',
  },{
    title: 'Grilled chicken wrap',
    price: '90,00 EGP',
    tag0: 'chicken + Cappage + Motzarella + Fries (AirFried)',
    tag1: '39 grams of protien + 58 grams of carbs + 12 grams of Fats',
  },
];

const cat9 = [
  {
    title: 'White sauce pasta',
    price: '25,00 EGP',
    tag0: '',
    tag1: '',
  },{
    title: 'Red sauce pasta',
    price: '20,00 EGP',
    tag0: '',
    tag1: '',
  },{
    title: 'Rice',
    price: '20,00 EGP',
    tag0: '',
    tag1: '',
  },{
    title: 'Sautee Vegetables',
    price: '20,00 EGP',
    tag0: '',
    tag1: '',
  },{
    title: 'Mashed Potato',
    price: '20,00 EGP',
    tag0: '',
    tag1: '',
  },{
    title: 'Potato wedges',
    price: '20,00 EGP',
    tag0: '',
    tag1: '',
  },
  {
    title: 'Fries (AirFried)',
    price: '20,00 EGP',
    tag0: '',
    tag1: '',
  },
]

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

export default { cat1, cat2, cat3, cat5, cat6, cat7, cat8, cat9,  awards };