import React from 'react';

import './MenuItem.css';

const MenuItem = ({ title, price, tag0, tag1 }) => (
  <div className="app__menuitem">
    <div className="app__menuitem-head">
      <div className="app__menuitem-name">
        <p className="p__cormorant" style={{ color: '#16b907' }}>{title}</p>
      </div>
      <div className="app__menuitem-dash" />
      <div className="app__menuitem-price">
        <p className="p__cormorant">{price}</p>
      </div>
    </div>

    <div className="app__menuitem-sub">
      <p className="p__opensans" style={{ color: '#000000' }}>{tag0}</p>
      <p className="p__opensans" style={{ color: '#000000' }}>{tag1}</p>

    </div>
  </div>
);

export default MenuItem;