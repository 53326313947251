import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About Us</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">We are a healthy food restaurant , where our commitment to your well-being goes beyond serving delicious and wholesome meals. Our goals are deeply rooted in fostering a healthier and happier community..</p>
        {/*<button type="button" className="custom__button">Know More</button>*/}
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our Goal</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">Our goal is to make healthy eating accessible to all, fostering a community of wellness and positive environmental impact.<br/> <br/></p>
        {/*<button type="button" className="custom__button">Know More</button>*/}
      </div>
    </div>
  </div>
);

export default AboutUs;