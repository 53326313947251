const cat1 = [
    {
      title: 'فطور مصري',
      price: '٨٥,٠٠ ج.م',
      tag0: 'بيض + فول + طعميه + جبنة قريش + بطاطس (مشوية بالهواء) + سلطة خضراء + توست',
      tag1: '٤٩ جرام من البروتين + ٦١ جرام من الكربوهيدرات + ٢٥ جرام من الدهون',
    },
    {
      title: 'توست متنوع بالجبنة',
      price: '٥٥,٠٠ ج.م',
      tag0: 'جبن متنوع + خس + بطاطس (مشوية بالهواء) + سلطة خضراء',
      tag1: '٢٤ جرام من البروتين + ٥٠ جرام من الكربوهيدرات + ٢٠ جرام من الدهون',
    },
    {
      title: 'توست دجاج مشوي',
      price: '٧٥,٠٠ ج.م',
      tag0: 'دجاج + خس + بطاطس (مشوية بالهواء) + موتزاريلا + سلطة',
      tag1: '٤٥ جرام من البروتين + ٧٤ جرام من الكربوهيدرات + ٢٣ جرام من الدهون',
    },
    {
      title: 'توست ديك رومي',
      price: '٦٥,٠٠ ج.م',
      tag0: 'ديك رومي + خس + موتزاريلا + بطاطس مقلية (مشوية بالهواء) + سلطة',
      tag1: '٣٦ جرام من البروتين + ٧٧ جرام من الكربوهيدرات + ١٧ جرام من الدهون',
    },
    {
      title: 'أمليت',
      price: '٦٥,٠٠ ج.م',
      tag0: '٢ بيض + ٢ توست + شرائح بطاطس + سلطة خضراء',
      tag1: '٢٥ جرام من البروتين + ٥٠.٨ جرام من الكربوهيدرات + ٢٢ جرام من الدهون',
    },
    {
      title: 'أمليت إسباني',
      price: '٦٥,٠٠ ج.م',
      tag0: '٢ بيض + فلفل + ٢ توست + موتزاريلا + شرائح بطاطس + سلطة خضراء',
      tag1: '٣٢ جرام من البروتين + ٥١.٢ جرام من الكربوهيدرات + ٣٠ جرام من الدهون',
    },
    {
      title: 'أمليت بالماشرووم',
      price: '٦٥,٠٠ ج.م',
      tag0: '٢ بيض + ماشرووم + ٢ توست + شرائح بطاطس + سلطة خضراء',
      tag1: '٢٥ جرام من البروتين + ٥١.٢ جرام من الكربوهيدرات + ٢٢ جرام من الدهون',
    },
  ];
  
  const cat2 = [
    {
      title: 'بون سانتي',
      price: '٢٣٥,٠٠ ج.م',
      tag0: 'لحم + أرز + بطاطس مهروسة + مقلية',
      tag1: '٥٣ جرام من البروتين + ٨٣ جرام من الكربوهيدرات + ١٢ جرام من الدهون ',
    },
    {
      title: 'كرات اللحم',
      price: '١٨٠,٠٠ ج.م',
      tag0: 'كرات اللحم + صلصة حمراء + أرز + بطاطس مهروسة + مقلية',
      tag1: '٥٢ جرام من البروتين + ٨٣ جرام من الكربوهيدرات + ١٩ جرام من الدهون',
    },
    {
      title: 'كفتة',
      price: '١٨٠,٠٠ ج.م',
      tag0: 'كفتة + أرز + مقلية + بطاطس مهروسة',
      tag1: '٥٠ جرام من البروتين + ٧٧ جرام من الكربوهيدرات + ١٤ جرام من الدهون',
    },
  ];
  
  const cat3 = [
    {
      title: 'دجاج مشوي',
      price: '١٤٥,٠٠ ج.م',
      tag0: 'دجاج مشوي + أرز + بطاطس مهروسة + مقلية',
      tag1: '٤٥.٥ جرام من البروتين + ٨٥ جرام من الكربوهيدرات + ٩ جرام من الدهون',
    },
    {
      title: 'دجاج بالليمون',
      price: '١٦٠,٠٠ ج.م',
      tag0: 'دجاج + صلصة ليمون + أرز + بطاطس مهروسة + مقلية',
      tag1: '٤٩ جرام من البروتين + ٨٥ جرام من الكربوهيدرات + ١٤ جرام من الدهون',
    },
    {
      title: 'دجاج مقرمش',
      price: '١٥٥,٠٠ ج.م',
      tag0: 'دجاج مقرمش + مكرونة بيضاء + مقلية + بطاطس مهروسة',
      tag1: '٥٠ جرام من البروتين + ٩٥ جرام من الكربوهيدرات + ١٣ جرام من الدهون',
    },
    {
      title: 'دجاج الفاهيتا',
      price: '١٥٠,٠٠ ج.م',
      tag0: 'فاهيتا + أرز + بطاطس مهروسة + مقلية',
      tag1: '٤٥.٥ جرام من البروتين + ٨٥ جرام من الكربوهيدرات + ٨.٥ جرام من الدهون',
    },
    {
      title: 'دجاج البطاطس المقرمشة',
      price: '٦٥,٠٠ ج.م',
      tag0: 'بطاطس مقرمشة + فاهيتا دجاج',
      tag1: '٣٤ جرام من البروتين + ٥٧ جرام من الكربوهيدرات + ١١ جرام من الدهون',
    },
    {
      title: 'ريزو',
      price: '٦٥,٠٠ ج.م',
      tag0: 'دجاج مقرمش + أرز + صلصة باربيكيو',
      tag1: '٣٢ جرام من البروتين + ٤٨ جرام من الكربوهيدرات + ١٢ جرام من الدهون',
    },
    {
      title: 'وجبة الأطفال',
      price: '٩٠,٠٠ ج.م',
      tag0: 'قطعتين من الشرائح + ٣ قطع من الناجتس + بطاطس مقلية (مشوية بالهواء) + أرز',
      tag1: '',
    },
  ];
  
  const cat5 = [
    {
      title: 'سلطة سيزر',
      price: '٨٥,٠٠ ج.م',
      tag0: 'دجاج مشوي + خس + توست + صلصة سيزر',
      tag1: '٣٧ جرام من البروتين + ٢٥ جرام من الكربوهيدرات + ١٤ جرام من الدهون',
    },
    {
      title: 'سلطة التونة',
      price: '٨٥,٠٠ ج.م',
      tag0: 'تونة + خس + بصل + فاصوليا حمراء + ذرة حلوة + فلفل حلو + طماطم كرز',
      tag1: '٢٣ جرام من البروتين + ٢٠ جرام من الكربوهيدرات + ٢.٤ جرام من الدهون',
    },
    {
      title: 'سلطة يونانية',
      price: '٧٥,٠٠ ج.م',
      tag0: 'جبن فيتا خفيف + خس + خيار + زيتون + طماطم كرز',
      tag1: '١٠ جرام من البروتين + ٢٥ جرام من الكربوهيدرات + ١٢ جرام من الدهون',
    },
    {
      title: 'سلطة خضراء',
      price: '٩٠,٠٠ ج.م',
      tag0: 'خس + بصل + فاصوليا حمراء + ذرة حلوة + فلفل حلو + طماطم كرز',
      tag1: '',
    },
  ];
  
  const cat6 = [
    {
      title: 'مكرونة الفريدو',
      price: '٩٥,٠٠ ج.م',
      tag0: 'دجاج + مكرونة + صوص أبيض',
      tag1: '٣٩.٥ جرام من البروتين + ٤٥ جرام من الكربوهيدرات + ١٣ جرام من الدهون',
    },
    {
      title: 'مكرونة الروبيان',
      price: '١٣٥,٠٠ ج.م',
      tag0: 'روبيان + مكرونة + صوص أبيض',
      tag1: '٣٠ جرام من البروتين + ٤٦ جرام من الكربوهيدرات + ١٣ جرام من الدهون',
    },
    {
      title: 'مكرونة بيستو',
      price: '٩٥,٠٠ ج.م',
      tag0: 'دجاج + مكرونة + صوص بيستو',
      tag1: '٤١.٥ جرام من البروتين + ٤٥ جرام من الكربوهيدرات + ٢٠ جرام من الدهون',
    },
    {
      title: 'مكرونة نيغريسكو',
      price: '١١٠,٠٠ ج.م',
      tag0: 'دجاج + مكرونة + صوص أبيض + موتزاريلا',
      tag1: '٥٠ جرام من البروتين + ٤٥ جرام من الكربوهيدرات + ٢٠ جرام من الدهون',
    },
  ];
  
  const cat7 = [
    {
      title: 'بيتزا الدجاج',
      price: '٨٥,٠٠ ج.م',
      tag0: 'دجاج + فلفل حلو + ماشرووم + موتزاريلا',
      tag1: '٣٣.٥ جرام من البروتين + ٢٠ جرام من الكربوهيدرات + ١٢ جرام من الدهون',
    },
    {
      title: 'بيتزا الدجاج المقرمشة',
      price: '٨٥,٠٠ ج.م',
      tag0: 'دجاج مقرمش + فلفل حلو + موتزاريلا',
      tag1: '٣٦ جرام من البروتين + ٢٥ جرام من الكربوهيدرات + ١٥ جرام من الدهون',
    },
    {
      title: 'بيتزا التونة',
      price: '١٠٠,٠٠ ج.م',
      tag0: 'تونة + فلفل حلو + موتزاريلا',
      tag1: '٢٥ جرام من البروتين + ١٢ جرام من الكربوهيدرات + ٨.٧ جرام من الدهون',
    },
  ];
  
  const cat8 = [
    {
      title: 'كريب الفاهيتا',
      price: '٨٥,٠٠ ج.م',
      tag0: 'دجاج + خس + فلفل حلو + بطاطس مقلية (مشوية بالهواء) + موتزاريلا',
      tag1: '٣٧ جرام من البروتين + ٥٥ جرام من الكربوهيدرات + ١١.٥ جرام من الدهون',
    },
    {
      title: 'كريب مقرمشة',
      price: '٨٥,٠٠ ج.م',
      tag0: 'دجاج + خس + موتزاريلا + بطاطس مقلية (مشوية بالهواء)',
      tag1: '٤٠ جرام من البروتين + ٥٨ جرام من الكربوهيدرات + ١٥.٥ جرام من الدهون',
    },
    {
      title: 'كريب دجاج مشوي',
      price: '٩٠,٠٠ ج.م',
      tag0: 'دجاج + خس + موتزاريلا + بطاطس مقلية (مشوية بالهواء)',
      tag1: '٣٩ جرام من البروتين + ٥٨ جرام من الكربوهيدرات + ١٢ جرام من الدهون',
    },
  ];
  
  const cat9 = [
    {
      title: 'مكرونة بصوص اللبن الأبيض',
      price: '٢٥,٠٠ ج.م',
      tag0: '',
      tag1: '',
    },
    {
      title: 'مكرونة بصوص الطماطم الأحمر',
      price: '٢٠,٠٠ ج.م',
      tag0: '',
      tag1: '',
    },
    {
      title: 'أرز',
      price: '٢٠,٠٠ ج.م',
      tag0: '',
      tag1: '',
    },
    {
      title: 'خضروات مشوية',
      price: '٢٠,٠٠ ج.م',
      tag0: '',
      tag1: '',
    },
    {
      title: 'بطاطس مهروسة',
      price: '٢٠,٠٠ ج.م',
      tag0: '',
      tag1: '',
    },
    {
      title: 'شرائح بطاطس',
      price: '٢٠,٠٠ ج.م',
      tag0: '',
      tag1: '',
    },
    {
      title: 'بطاطس مقلية (مشوية بالهواء)',
      price: '٢٠,٠٠ ج.م',
      tag0: '',
      tag1: '',
    },
  ];
  
  export default { cat1, cat2, cat3, cat5, cat6, cat7, cat8, cat9, };
  